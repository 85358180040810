<template>
    <v-card flat tile>
         <v-progress-circular indeterminate size="65" v-if="loading" />
         <template v-else-if="space !== null">
               <v-card-title>
                   <span class="h4-text" v-if="space && space.shortname">{{ space.shortname }}</span>
                    <v-spacer/>

                    <v-btn @click="close !== undefined ? close : false " v-if="space.managedBy.includes('/api/members/'+$store.getters.user.data.id)" icon :to="{name: 'editSpace', params: {spaceId: space.id}}"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn v-if="close !== undefined" icon @click="close">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
               </v-card-title>
        <v-divider/>
        <v-card-text>
           <v-list>

               <v-list-item>
                   <v-list-item-content>
                        <v-list-item-subtitle>
                             {{ $t('space.view.fullname')}}
                       </v-list-item-subtitle>
                       <v-list-item-title>
                            {{ space.fullname }}
                       </v-list-item-title>
                   </v-list-item-content>
                </v-list-item>
                 <v-list-item>
                   <v-list-item-content>
                        <v-list-item-subtitle>
                             {{ $t('space.view.shortname')}}
                       </v-list-item-subtitle>
                       <v-list-item-title>
                            {{ space.fullname }}
                       </v-list-item-title>
                   </v-list-item-content>
                </v-list-item>
  <v-list-item v-if="space.location && space.location.place_name">
                   <v-list-item-content>
                        <v-list-item-subtitle>
                             {{ $t('space.view.location')}}
                       </v-list-item-subtitle>
                       <v-list-item-title>
                            <a target="_blank" :href="'http://maps.google.com/maps?q=loc:'+space.location.location[1]+','+space.location.location[0]+''"> {{ space.location.place_name }}</a>

                       </v-list-item-title>
                   </v-list-item-content>
                </v-list-item>

                  <v-list-item>
                   <v-list-item-content>
                        <v-list-item-subtitle>
                             {{ $t('space.view.createdAt')}}
                       </v-list-item-subtitle>
                       <v-list-item-title>
                            {{ space.createdAt | printDate }}
                       </v-list-item-title>
                   </v-list-item-content>
                </v-list-item>
                <v-list-item>
                   <v-list-item-content>
                        <v-list-item-subtitle>
                             {{ $t('space.view.updatedAt')}}
                       </v-list-item-subtitle>
                       <v-list-item-title>
                            {{ space.updatedAt | printDate }}
                       </v-list-item-title>
                   </v-list-item-content>
                </v-list-item>

           </v-list>

        </v-card-text>
         </template>

    </v-card>
</template>
<script>

export default {
  name: 'SpaceDetails',
  props: ['spaceId', 'close'],
  data: () => ({
    space: null,
    loading: false
  }),
  filters: {
    printDate (v) {
      return new Date(v).toLocaleDateString() + ' ' + new Date(v).toLocaleTimeString()
    }
  },
  mounted () {
    this.loading = true
    this.$http.get('api/spaces/' + this.spaceId).then((d) => {
      this.space = d.data
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>
