<template>
    <v-dialog fullscreen  v-model="dialogJoin">
        <v-card flat tile>
            <v-card-title>
                <h2 class="text-h5">
                    {{ $t('dialogJoin.title')  }}
                </h2>
                <v-spacer/>
                <v-btn icon @click="dialogJoin = false;">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
                 <AddMembership v-if="space" :space="space" :close="() => { this.dialogJoin = false; }" />
            </v-card-text>
            </v-card>
    </v-dialog>
</template>
<script>
import AddMembership from '../memberships/add.vue'
export default {
  data: () => ({
    dialogJoin: false,
    space: null
  }),
  components: { AddMembership },
  mounted () {
    this.$root.$on('dialogJoin', (s) => {
      this.space = s
      this.dialogJoin = true
    })
  }
}
</script>
