import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)
// In your router initialization code

const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/components/HelloWorld.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "home" */ '@/components/404.vue')
  },
  {
    path: '/403',
    name: 'forbidden',
    component: () => import(/* webpackChunkName: "home" */ '@/components/403.vue')
  },
  {
    path: '/resetPassword/:token',
    name: 'ResetPassword',
    props: true,
    component: () => import(/* webpackChunkName: "home" */ '@/components/utils/dialogResetPassword')
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import(/* webpackChunkName: "home" */ '@/components/events/agenda.vue'),
    beforeEnter: async (to, from, next) => {
      console.log(store.getters.user.logged)
      // await store.dispatch('init');
      if (store.getters.user.logged) {
        next()
      } else {
        next({ name: 'Home', hash: '#login', params: { redirect: to } })
      }
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "home" */ '@/components/members/signup.vue')
  },
  {
    path: '/memberships',
    name: 'Memberships',
    component: () => import(/* webpackChunkName: "home" */ '@/components/memberships/list.vue')
  },
  {
    path: '/spaces',
    component: () => import(/* webpackChunkName: "home" */ '@/components/spaces/index.vue'),
    children: [
      {
        path: '',
        name: 'listSpace',
        component: () => import(/* webpackChunkName: "home" */ '@/components/spaces/list.vue')
      },
      {
        path: 'add',
        name: 'addSpace',
        component: () => import(/* webpackChunkName: "home" */ '@/components/spaces/add.vue')
      },
      {
        path: ':spaceId',
        name: 'detailSpace',
        component: () => import(/* webpackChunkName: "home" */ '@/components/spaces/details.vue'),
        props: true
      },

      {
        path: ':spaceId/admin/',
        name: 'adminSpace',
        component: () => import(/* webpackChunkName: "home" */ '@/components/spaces/admin/index.vue'),
        props: true,
        meta: { requireAuth: true, requireIsManager: true },

        children: [
          {
            path: '',
            props: true,
            meta: { requireAuth: true, requireIsManager: true },
            name: 'indexAdmin',
            component: () => import(/* webpackChunkName: "home" */ '@/components/spaces/admin.vue')
          },
          {
            path: 'memberships',
            props: true,
            name: 'membershipsList',
            meta: { requireAuth: true, requireIsManager: true },
            component: () => import(/* webpackChunkName: "home" */ '@/components/spaces/admin/memberships.vue')
          },
          {
            path: 'edit',
            name: 'editSpace',
            meta: { requireAuth: true, requireIsManager: true },
            component: () => import(/* webpackChunkName: "home" */ '@/components/spaces/edit.vue'),
            props: true
          }
        ]
      }
    ]

  }

]
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to && to.meta && to.meta.requireAuth === true && to.meta.requireIsManager === true) {
    const watcher = store.watch((state) => state.loaded, (loaded) => {
      if (loaded) {
        watcher()

        if (to.params && to.params.spaceId) {
          console.log(store.getters.user.data.managedSpaces.map(s => s.id).includes(to.params.spaceId))
          if (store.getters.user.data.managedSpaces.map(s => s.id).includes(to.params.spaceId)) {
            return next()
          } else {
            return next({ name: 'forbidden' })
          }
        }
      }
    })
  }

  next()
})

export default router
