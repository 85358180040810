<template>
    <v-container>

        <v-alert  type="warning" v-if="alert !== null">
            {{ alert }}
        </v-alert>
        <FormMembership :submitting="submitting" :submit="submit" action="add" :spaceId="space.id" />
    </v-container>
</template>
<script>
import FormMembership from '../forms/membership.vue'
export default {
  name: 'MembershipsAdd',
  props: ['space', 'close'],
  components: { FormMembership },
  data: () => ({
    submitting: false,
    alert: null
  }),
  methods: {
    submit (f) {
      this.submitting = true
      f.member = '/api/members/' + this.$store.getters.user.data.id
      this.$http.post('api/memberships', f).finally(() => {
        this.close()
        this.$router.push({ name: 'Memberships' })
      }).catch((e) => {
        this.alert = e.response.data['hydra:description']
      }).finally(() => {
        this.submitting = false
      })
    }
  }
}
</script>
