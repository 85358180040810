<template>
    <v-navigation-drawer
      v-model="drawer_"
      absolute
      id="navigationDrawer"
      temporary
    >
    <v-list>

      <v-list-item :to="{name: 'Home'}">
            <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ $t('menu.home') }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

 <template v-if="!this.$store.getters.isLogged" >
  <v-list-item :to="{name: 'Signup'}" id="signupBtn">
            <v-list-item-icon>
                <v-icon>mdi-account-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ $t('menu.signup') }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
          <v-list-item  @click="$root.$emit('dialogLogin')" id="loginBtn">
            <v-list-item-icon>
                <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ $t('menu.signin') }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
 </template>
 <template v-else>
     <v-list-item :to="{name: 'listSpace'}">
            <v-list-item-icon>
                <v-icon>mdi-home-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ $t('menu.spaces') }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
         <v-list-item :to="{name: 'Agenda'}">
            <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ $t('menu.agenda') }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
         <v-list-item :to="{name: 'addSpace'}">
            <v-list-item-icon>
                <v-icon>mdi-home-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ $t('menu.addSpace') }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

 </template>
 <template v-if="this.$store.getters.isLogged && $store.getters.user.data.managedSpaces.length > 0" >
 <v-subheader>Admin</v-subheader>
    <v-list-item  v-for="(space,i) of $store.getters.user.data.managedSpaces" :key="'menu_space_'+i" :to="{name:'indexAdmin', params: {spaceId: space.id}}">
            <v-list-item-content>
                <v-list-item-title>
                    {{ space.shortname }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
 </template>

    </v-list>
    </v-navigation-drawer>

</template>
<script>
export default {
  props: ['drawer', 'setDrawer'],
  mounted () {
    console.log(this.$store.getters)
  },
  computed: {
    drawer_: {
      get: function () {
        return this.drawer
      },
      set: function (v) {
        this.setDrawer(v)
      }
    }
  }
}
</script>
