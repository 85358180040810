<template>
    <v-dialog max-width="400" v-model="dialogLogin" >

        <v-card>
            <v-alert type="warning" v-if="alert" tile flat>
                {{ alert }}
            </v-alert>
            <v-card-title>
                <h2 class="text-h5">
                    {{ $t('menu.auth.signin.title')  }}
                </h2>
                <v-spacer/>
                <v-btn icon @click="dialogLogin = false;">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-form @submit.prevent="submit(form)" class="mt-4">
                        <v-text-field required outlined v-model="form.email" :label="$t('member.signin.form.email')" />
                        <v-text-field  type="password" required outlined v-model="form.password" :label="$t('member.signin.form.password')" />
                        <v-checkbox v-model="stay_connected" :label="$t('member.signin.form.stayLogged')" />
                    <v-btn  :loading="loadingLogin" color="primary" block type="submit" :disabled="!formValid">
 {{ $t('member.signin.form.submit')}}
                    </v-btn>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="$root.$emit('dialogPasswordLost', {email: form.email})">{{$t('member.signin.actions.password_lost') }}</v-btn>
                <v-spacer />
                <v-btn :to="{'name': 'Signup'}" @click="dialogLogin = false;" text>{{$t('menu.signup') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
  name: 'DialogLogin',
  props: ['close'],
  data: () => ({
    alert: null,
    form: { email: '', password: '' },
    dialogLogin: false,
    options: {},
    stay_connected: false,
    loadingLogin: false
  }),
  computed: {
    formValid: function () {
      return this.form.email.length > 0 && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(this.form.email) && this.form.password.length >= 8
    }
  },
  methods: {
    submit (f) {
      this.alert = null
      this.loadingLogin = true
      this.$http.post('api/login_check', f).then((d) => {
        if (d.data) {
          let data = d.data
          console.log(data)
          if (data.token) {
            if (this.stay_connected) {
              data = { ...data, email: this.form.email, password: this.form.password }
            }
            this.$store.dispatch('login', data)
            if (data && data.user && data.user) {
              console.log(data.user.managedMemberships.filter(m => m.active))
              console.log(data.user.memberships.filter(m => m.active))

              // let memberships = {...data.user.memberships.filter(m => m.active), ...data.user.managedMemberships.filter(m => m.active)}
            }
            //
            this.form = { email: '', password: '' }
            this.dialogLogin = false
            if (this.options && this.options.redirect) {
              console.log(this.options.redirect)
              this.$router.push({ name: this.options.redirect.name })
            } else {
              this.$router.go()
            }
          }
        }
      }).catch((e) => {
        this.alert = e.response.data.code === 401 ? this.$t('error.401') : this.$t('error.other')
      }).finally(() => {
        this.loadingLogin = false
      })
      console.log(f)
    }
  },
  mounted () {
    this.$root.$on('dialogLogin', (o) => {
      this.options = o
      this.dialogLogin = true
      if (this.options && this.options.plainPassword) {
        this.form.password = this.options.plainPassword
      }
      if (this.options && this.options.email) {
        this.form.email = this.options.email
      }
      console.log(this.options)
    })
    this.$store.subscribeAction((mutation) => {
      if (mutation.type === 'showDialogLogin') {
        this.dialogLogin = true
      }
      if (mutation.type === 'submitLogin' && this.$store.getters.stay_connected !== null) {
        this.submit(this.$store.getters.stay_connected)
      }
    })
  }
}
</script>
