<template>
    <v-form  @submit.prevent="submit(getModel)">

              <v-menu
        v-model="menu_startDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          outlined
            v-model="formatedStartDate"
            :label="$t('membership.form.startDate')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
         locale="fr"
          v-model="getModel.startDate"
          @input="menu_startDate = false"
        ></v-date-picker>
      </v-menu>
      <v-menu
        v-model="menu_endDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          outlined
            v-model="formatedEndDate"
            :label="$t('membership.form.endDate')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
         locale="fr"
          v-model="getModel.endDate"
          @input="menu_endDate = false"
        ></v-date-picker>
      </v-menu>
      <v-select :label="$t('membership.form.federation')" outlined v-if="space && Object.keys(space).includes('federations')" v-model="form.federation" return-object item-text="shortname" :items="space.federations">

      </v-select>
      <v-row class="text-center justify-center ma-5">
<v-btn-toggle v-model="who"  background-color="primary" dark >
    <v-btn text value="me">
            {{ $t('membership.form.who.me') }}
        </v-btn>
        <v-btn text value="other">
            {{ $t('membership.form.who.other') }}
        </v-btn>

</v-btn-toggle>
      </v-row>
      <v-text-field v-model="getModel.data.name" outlined :label="$t('membership.form.name')">

      </v-text-field>
       <v-text-field v-model="getModel.data.firstname" outlined :label="$t('membership.form.firstname')">

      </v-text-field>
        <v-text-field v-model="getModel.data.chip" outlined :label="$t('membership.form.chip')">

      </v-text-field>
       <v-text-field v-model="getModel.data.licence" outlined :label="$t('membership.form.licence')">

      </v-text-field>
      <v-select outlined v-model="getModel.data.category" :items="categories" :label="$t('membership.form.category')">

      </v-select>

      <v-btn color="primary" type="submit" block :loading="submitting" :disabled="!formValid">{{ $t('membership.form.submit_'+this.action) }}</v-btn>

    </v-form>
</template>
<script>

export default {
  name: 'FormMembership',
  props: ['action', 'data', 'spaceId', 'submit', 'submitting'],
  data: () => ({
    menu_startDate: false,
    menu_endDate: false,
    space: null,
    who: null,
    form: { spaceApproved: false, memberApproved: true, space: null, member: null, startDate: null, endDate: null, data: { name: '', firstname: '' } }
  }),
  watch: {
    who: function (v) {
      if (v === 'me') {
        this.getModel.data.name = this.$store.getters.user.data.name
        this.getModel.data.firstname = this.$store.getters.user.data.firstname
        this.getModel.member = this.$store.getters.user.data['@id']
      } else {
        this.getModel.data.name = ''
        this.getModel.member = null
        this.getModel.data.firstname = ''
      }
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  },
  computed: {
    categories: function () {
      if (this.getModel.federation && this.getModel.federation.shortname === 'FFCO') {
        const a = [];
        ['D', 'H'].forEach((v) => {
          ['10', '12', '14', '16', '18', '20', '21', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80'].forEach((z) => {
            a.push(v + z)
          })
        })
        return a
      } else {
        return []
      }
    },
    formatedStartDate: function () {
      return this.formatDate(this.getModel.startDate)
    },
    formatedEndDate: function () {
      return this.formatDate(this.getModel.endDate)
    },
    getModel: function () {
      return this.data ? this.data : this.form
    },
    formValid: function () {
      return this.getModel.space !== null && this.getModel.startDate !== null && this.getModel.endDate !== null && this.getModel.data.name.length > 0 && this.getModel.data.firstname.length > 0
    }
  },
  mounted () {
    if (this.spaceId) {
      this.$http.get('api/spaces/' + this.spaceId).then((d) => {
        this.space = d.data
        this.getModel.space = this.space['@id'] ? this.space['@id'] : '/api/spaces/' + this.space.id
      })
    }

    if (this.action === 'add') {
      this.form.active = true

      this.form.managedBy = [this.$store.getters.user.data['@id'] ? this.$store.getters.user.data['@id'] : '/api/members/' + this.$store.getters.user.data.id]
      this.form.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.form.endDate = (new Date(new Date().getFullYear(), 11, 32)).toISOString().substr(0, 10)
      this.who = 'me'
    } else {
      this.form.startDate = this.form.startDate.substr(0, 10)
      this.form.endDate = this.form.endDate.substr(0, 10)
    }
  }
}
</script>
