<template>
    <v-dialog max-width="400" v-model="dialogPasswordLost" >
        <v-card>
            <v-card-title>
                <h2 class="text-h5">
                    {{ $t('menu.auth.lost_password.title')  }}
                </h2>
                <v-spacer/>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-form @submit.prevent="submit(form)" class="mt-4" v-if="step === 1">
                        <v-text-field required outlined v-model="form.email" :label="$t('member.signin.form.email')" />
                    <v-btn  :loading="loadingPasswordLost" color="primary" block type="submit" :disabled="!formValid">
  {{ $t('menu.auth.lost_password.submit')  }}
                    </v-btn>
                </v-form>
                <div v-else>
                      <v-alert type="info" outlined >
                     {{$t('menu.auth.lost_password.confirmation') }}
                    </v-alert>
                    <v-btn @click="close">
            {{$t('menu.auth.lost_password.closeBtn') }}
                    </v-btn>
                </div>

            </v-card-text>

        </v-card>
    </v-dialog>
</template>
<script>
export default {
  data: () => ({
    step: 1,
    dialogPasswordLost: false,
    form: { email: '' },
    loadingPasswordLost: false
  }),
  methods: {
    close () {
      this.dialogPasswordLost = false
      this.form = { email: '' }
      this.step = 1
      this.$router.push({ name: 'Home' })
    },
    submit (f) {
      this.loadingPasswordLost = true
      this.$http.post('forgot_password/', f).then(() => {
        this.step = 2
      }).finally(() => {
        this.loadingPasswordLost = false
      })
    }
  },
  computed: {
    formValid: function () {
      return this.form.email.length > 0 && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(this.form.email)
    }
  },
  mounted () {
    this.$root.$on('dialogPasswordLost', (e) => {
      if (e && e.email !== null) {
        this.form.email = e.email
      }
      this.dialogPasswordLost = true
    })
  }
}
</script>
