<template>
  <v-app>
    <v-app-bar
      app
      dark

      color="primary"
    >
    <v-app-bar-nav-icon id="menu" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-app-bar-title><span class="font-weight-bold">O'Club</span></v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="!$store.getters.user.logged" icon @click="$root.$emit('dialogLogin')">
          <v-icon>mdi-login</v-icon>
        </v-btn>
        <v-btn icon v-else @click="logout">
            <v-icon>mdi-logout</v-icon>
        </v-btn>
        <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >

    <template v-slot:activator="{ on, attrs }">
        <v-btn  v-bind="attrs"
          v-on="on" icon v-if="$store.getters.user.logged">
          <v-icon>mdi-account-switch</v-icon>
        </v-btn>
        </template>
              <v-card>
                <v-card-title>

                </v-card-title>
                <v-card-text>
                  <p>
                      {{ $t('menu.switchMembership.explanations')}}
                  </p>
<v-select return-object outlined v-if="$store.getters.user.logged" v-model="currentActiveMembership" :item-text="itemTextActiveMembership" :items="memberships">

        </v-select>
                </v-card-text>
</v-card>
</v-menu>

    </v-app-bar>
<NavigationDrawer :drawer="drawer" :setDrawer="(d) => { this.drawer = d; }" />
    <v-main class="ma-0" :style="{'padding-top': '56px'}">
      <router-view />
    </v-main>
    <DialogLogin  :close="()=>{ this.dialogLogin = false; }"  />
      <DialogJoin />
      <DialogPasswordLost />
      <DialogSpaceDetail />
  </v-app>
</template>

<script>
import DialogLogin from './components/utils/dialogLogin.vue'
import DialogJoin from './components/utils/dialogJoin.vue'
import DialogPasswordLost from './components/utils/dialogPasswordLost.vue'
import DialogSpaceDetail from './components/utils/dialogSpaceDetail.vue'
import NavigationDrawer from './components/NavigationDrawer.vue'
export default {
  name: 'App',
  components: { DialogLogin, DialogJoin, NavigationDrawer, DialogPasswordLost, DialogSpaceDetail },
  async mounted () {

  },
  watch: {
    $route: function (to) {
      if (to.hash && to.hash === '#login' && !this.$store.getters.user.logged) {
        this.$root.$emit('dialogLogin', to.params)
      }
      console.log(to)
    }
  },
  computed: {
    memberships: function () {
      const userData = this.$store.getters.user.data
      return [...userData.memberships, ...userData.managedMemberships]
    },
    currentActiveMembership: {
      get: function () {
        return this.$store.getters.activeMembership
      },
      set (v) {
        this.$store.dispatch('setActiveMembership', v)
      }
    }
  },
  methods: {
    logout () {
      if (window.confirm(this.$t('menu.confirmLogout'))) {
        this.$store.dispatch('logout')
        this.$router.go()
      }
    },
    itemTextActiveMembership (v) {
      return v.data.name + ' ' + v.data.firstname + ' (' + (v.space.shortname ? v.space.shortname : v.space.fullname) + ')'
    }
  },
  data: () => ({
    dialogLogin: false,
    menu: false,
    drawer: false
  })
}
</script>
