<template>
    <v-dialog v-model="dialog" persistent fullscreen>
        <SpaceDetails :close="close" v-if="spaceId !== null" :spaceId="spaceId" />
    </v-dialog>
</template>
<script>
import SpaceDetails from '../spaces/details.vue'
export default {
  data: () => ({
    dialog: false,
    spaceId: null
  }),
  components: { SpaceDetails },
  methods: {
    close: function () {
      this.dialog = false
      this.spaceId = null
    }
  },
  mounted () {
    this.$root.$on('dialogSpaceDetail', (spaceId) => {
      this.spaceId = spaceId
      this.dialog = true
    })
  }
}
</script>
