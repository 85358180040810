import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loaded: false,
    user: { logged: false, token: null, profile: null },
    stay_connected: null,
    memberships: [],
    activeMembership: null
  },
  getters: {
    loaded: state => state.loaded,
    isLogged: state => state.user.logged,
    user: state => state.user,
    activeMembership: state => state.activeMembership,
    stay_connected: state => state.stay_connected
  },

  mutations: {
    setActiveMembership (state, payload) {
      state.activeMembership = payload
    },
    logout (state) {
      state.user.logged = false
      state.user.token = null
      state.user.data = null
      state.activeMembership = null
    },
    login (state, payload) {
      state.user.logged = true
      state.user.token = payload.token
      state.user.data = payload.user
      console.log(state.user)
      if (payload.email && payload.password) {
        state.stay_connected = { email: payload.email, password: payload.password }
      } else {
        state.stay_connected = null
      }
      state.memberships = [...payload.user.memberships, ...payload.user.managedMemberships]
      state.activeMembership = state.memberships.filter(m => m.active === true && m.memberApproved === true && m.spaceApproved).shift()
    },
    async initialiseStore (state) {
      if (localStorage.getItem('store')) {
        // Replace the state object with the stored item
        await this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        )
        state.loaded = true
      }
    }
  },
  actions: {

    showDialogLogin () {

    },
    submitLogin () {

    },
    login ({ commit }, payload) {
      commit('login', payload)
    },
    logout ({ commit }) {
      commit('logout')
    },
    setActiveMembership ({ commit }, payload) {
      commit('setActiveMembership', payload)
    }

  },
  modules: {}
})

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state))
})
export default store
