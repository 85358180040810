import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './i18n'
import axios from 'axios'
import store from './store'
import DatetimePicker from 'vuetify-datetime-picker'
import interceptors from './interceptors'
import './registerServiceWorker'
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(DatetimePicker)

axios.defaults.baseURL = process.env.VUE_APP_API_URL

interceptors()

new Vue({
  vuetify,
  store,
  router,
  i18n,
  beforeCreate () {
    this.$store.commit('initialiseStore')
  },
  render: h => h(App)
}).$mount('#app')
