import axios from 'axios'
import store from './store'

export default function setup () {
  axios.interceptors.request.use(function (config) {
    if (config.method === 'patch') {
      config.headers['Content-Type'] = 'application/merge-patch+json'
    } else {
      config.headers['Content-Type'] = 'application/json'
    }

    if (store.getters.user && store.getters.user.token) {
      if (store.getters.user.logged) {
        config.headers.Authorization = 'Bearer ' + store.getters.user.token
        if (config.url === '/members' && config.method === 'post') {
          delete config.headers.Authorization
        }
      }
    }
    return config
  }, function (err) {
    return Promise.reject(err)
  })

  axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    console.log(error)
    // const originalRequest = error.config;

    const dataResponse = error.response.data
    if (dataResponse.code === 401) {
      store.dispatch('logout')
      if (store.getters.stay_connected !== null) {
        // call login method with data (email/pwd) directly
        // need to replay request

        if (error.config.url !== 'api/login_check') {
          store.dispatch('submitLogin')
        }
        //
      } else {
        // Open dialog in dialogLogin
        // Check if need to replay request or refresh
        store.dispatch('showDialogLogin')
      }

      // this.$emit('dialogLogin');
    } else if ([500, 501, 503].includes(dataResponse.code)) {
      alert('Désolé, le problème semble provenir du serveur.')
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  })
}
